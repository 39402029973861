import React from 'react';
import Donutreactchart from './icon/donutreactchart';
import TagsAnnotator from './icon/tagsAnnotator';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Grid, Typography } from '@mui/material';

export const InterviewData = ({ resumeData }: any) => {
  const Today = new Date();
  const oneWeekBefore = new Date(Today);
  oneWeekBefore.setDate(Today.getDate() - 6);
  const navigate = useNavigate();
  const ResumeFinalData = resumeData && resumeData.length > 0;
  const defaultData = [
    {
      name: 'Interview Scheduled',
      color: '#14B8A6',
    },
    {
      name: 'Interview Selected',
      color: '#F59E0B',
    },
    {
      name: 'Interview Rejected',
      color: '#3B82F6',
    },
    {
      name: 'Interview Rescheduled',
      color: '#A855F7',
    },
  ];
  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Interview </div>
        <div className='viewdetail' onClick={() => navigate('/interviews')}>
          View Details
        </div>
      </header>
      {!ResumeFinalData ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '225px',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          No data available
        </p> // Placeholder message
      ) : (
        <main>
          <div
            className='donutchartout'
            style={{ display: 'flex', justifyContent: 'center', marginLeft: '50px' }}>
            <Donutreactchart resumeData={resumeData} interview={true} />
          </div>
          <div className='labelcon'>
            {defaultData.map((er: any, idx: number) => {
              return (
                <div className='labelItem' key={idx}>
                  <TagsAnnotator txt={er.name} bg={er.color} />
                </div>
              );
            })}
          </div>
        </main>
      )}
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'p'}
          className='custom-field'
          style={{
            marginTop: '20px',
            border: '1px solid #E4E7EC',
            borderRadius: '0px 0px 8px 8px',
            padding: '8px',
            backgroundColor: '#F2F4F7',
            fontSize: '14px',
            fontFamily: 'Poppins-Regular !important',
          }}>
          Interview for the Period{' '}
          <Typography
            component={'span'}
            style={{ fontSize: '14px', fontFamily: 'Poppins-Regular !important' }}>
            {`${moment(Today).format('DD-MM-YYYY')} to ${moment(oneWeekBefore).format(
              'DD-MM-YYYY',
            )}`}
          </Typography>
        </Typography>
      </Grid>
    </div>
  );
};
